import './confirmation.css';
import {useEffect, useLayoutEffect, useState} from 'react'

import mels from '../assets/interac.png'

const Confirmation = ({language, meetId}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [meet, setMeet] = useState(0);
    const [labels] = useState({"no-upcoming-meets": {"french": "Aucune compétition à afficher pour l'instant",
                                                        "english": "No competition to display for the moment"},
                                "registration": {"french": "Inscription", "english": "Registration"},
                                "congrats": {"french": "Félicitations!", "english": "Congratulations!"},
                                "description": {"french": "Votre inscription a été enregistrée. Votre inscription sera complétée seulement quand le directeur ou la directrice recevra votre paiement.", 
                                                "english": "Your registration has been saved. Your registration will only be completed when the meet director receives your payment."},
                                "step1": {"french": "Étape 1: ", "english": "Step 1: "},
                                "step2": {"french": "Étape 2: ", "english": "Step 2: "},
                                "step3": {"french": "Étape 3: ", "english": "Step 3: "},
                                "amount": {"french": "$ (taxes incluses)",
                                        "english": "$ (taxes included)"},
                                "method": {"french": "Voici les modes de paiement supportés:", "english": "Here are the supported payment methods:"},
                                "q": {"french": "Question à poser : ", "english": "Question to ask: "},
                                "qf": {"french": "En quelle année sommes-nous?", "english": "What year is it?"},
                                "a": {"french": "Réponse : ", "english": "Answer : "},
                                "email": {"french": "Courriel pour l’envoi de paiement: ", "english": "Email for sending payment: "},
                                "thanks": {"french": "MERCI!", "english": "THANK YOU!"},
                                "documents": {"french": "Remplir les documents suivants: ", "english": "Complete the following documents:"},
                                "send": {"french": "Et les envoyer à l'organisateur de l'événement à l'adresse suivante: mofobarbell@outlook.com",
                                                 "english": "And send them to the meet director at the following address: mofobarbell@outlook.com"},
                                "details3": {"french": "S'assurer d'avoir une carte de membre valide et le certificat du CCES en main le jour de la compétition (papier ou numérique). Sinon, vous ne pourrez prendre part à la compétition.",
                                            "english": "Make sure you have a valid membership card and CCES certificate on hand on the day of the competition (paper or digital). Otherwise, you will not be able to take part in the competition."},
                            });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId, {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setMeet(myJson[0]);
            });
    // eslint-disable-next-line
    }, []);

    return (
        <div className = "confirmation-container">
            <h2>{labels['registration'][language["language"]]}</h2>
            <h1>{meet.eventName}</h1>
            <p className="congrats">{labels['congrats'][language["language"]]}</p>
            <p className="description">{labels['description'][language["language"]]}</p>

            <p className="step">{labels['step1'][language["language"]]} </p>
            <p>{meet.cost + labels['amount'][language["language"]]}</p>
            <p>{labels['method'][language["language"]]}</p>
            <img className="interac" src={mels} alt=""/>
            <p><b>{labels['q'][language["language"]]}</b> {labels['qf'][language["language"]]}</p>
            <p><b>{labels['a'][language["language"]]} </b> {new Date().getFullYear()}</p>
            <p><b>{labels['email'][language["language"]]}</b> {meet.contactEmail}</p>

            <br /><br /><br /><br />

{/* 
            <p className="step">{labels['step2'][language["language"]]} </p>
            <p>{labels['documents'][language["language"]]}</p>
            <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1rvKTffVxALrK5vv330laILnZIsXAvYy0?usp=sharing">DOCUMENTS</a>
            <p>{labels['send'][language["language"]]}</p>

            <br /><br /><br /><br />
             */}

            <p className="step">{labels['step2'][language["language"]]}</p>
            <p>{labels['details3'][language["language"]]}</p>

            <br /><br /><br /><br />

            <p className="step">{labels['thanks'][language["language"]]}</p>
        </div>
    );
}

export default Confirmation
